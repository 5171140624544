
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Card,
  GridList,
  GridListTile,
} from "@material-ui/core";
import Link from "@components/Link";
import useWindowSize from "hooks/useWindowSize";
import Image from "next/image";
import theme from "@components/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    backgroundColor: "#fafafa",
    paddingBottom: "70px",
  },
  card: {
    width: "200px",
    boxShadow: "none",
    marginBottom: "35px",
    backgroundColor: "#fafafa",
    borderRadius: "2px",
  },
  image_color: {
    webkitFilter: "grayscale(100%)",
    filter: "grayscale(100%)",
    "&:hover": {
      webkitFilter: "grayscale(0%)",
      filter: "grayscale(0%)",
    },
  },
  list_container: {
    flexWrap: "nowrap",
    WebkitScrollSnapType: "none",
  },
  collab_list: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    justifyContent: "space-around",
    margin: "20px",
  },
  collab_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "20px",
  },
}));

const chargers = [
  {
    url: "https://shop.evloader.com/all-products/",
    img: "/images/chargers/abb.png",
    alt: "ABB",
  },
  {
    url: "https://shop.evloader.com/all-products-2/",
    img: "/images/chargers/circontrol.png",
    alt: "CIRCONTROL",
  },
  {
    url: "https://shop.evloader.com/all-products-2/",
    img: "/images/chargers/evbox.png",
    alt: "EVBOX",
  },
  {
    url: "https://shop.evloader.com/wallbox-products/",
    img: "/images/chargers/wallbox.png",
    alt: "wallbox",
  },
];

function SupportedChargers() {
  const classes = useStyles();
  const { t } = useTranslation();
  const size = typeof window !== "undefined" ? useWindowSize() : { width: 0 };

  const getResponsiveColumn = () => {
    if (size.width < 575) {
      return 1;
    } else if (size.width < 810) {
      return 2;
    } 
     else {
      return 3;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.collab_list}>
        <Box className={classes.collab_container}>
          <Typography
            gutterBottom
            component="h2"
            variant="h5"
            style={{ alignSelf: "center", marginBottom: "20px" }}
          >
            Supported Chargers
          </Typography>
          <Swiper
            style={{
              "--swiper-navigation-color": theme.palette.primary.main,
              "--swiper-pagination-color": theme.palette.primary.main,
            }}
            slidesPerView={getResponsiveColumn()}
            slidesPerGroup={getResponsiveColumn()}
            // slidesPerView={3}
            // slidesPerGroup={3}
            spaceBetween={30}
            loop={false}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <GridList
              className={classes.list_container}
              cols={getResponsiveColumn()}
              cellHeight="auto"
            >
              {chargers.map((charger, index) => (
                <GridListTile
                  key={`supported-charger-${index}`}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <SwiperSlide>
                    <Link target="_blank" href={charger.url} naked>
                      <Card className={classes.card}>
                        <div
                          style={{
                            position: "relative",
                            width: "auto",
                            height: "50px",
                            borderRadius: "3px",
                            filter: `grayscale('100%')`,
                          }}
                        >
                          <Image
                            src={charger.img}
                            title={charger.alt}
                            alt={charger.alt}
                            layout="fill"
                            objectFit="contain"
                            quality="40"
                            className={classes.image_color}
                          />
                        </div>
                      </Card>
                    </Link>
                  </SwiperSlide>
                </GridListTile>
              ))}
            </GridList>
          </Swiper>
        </Box>
      </div>
    </div>
  );
}

export default SupportedChargers;

